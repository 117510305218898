<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          class="elevation-1"
          :headers="conceptosPorPeriodoHeaders"
          :items="itemsPorPeriodo"
          item-key="adiPorPeriId"
          :search="searchPorPeriodo"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="searchPorPeriodo"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.importe`]="{ item }">
            <span>{{ addDecimals(item.importe) }}</span>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="itemsPorPeriodo.length === 0"
          color="primary"
          outlined
          @click="exportExcel()"
          class="to-right fontsize"
        >
          Exportar detalle
        </v-btn>
        <v-btn outlined @click="closeModal"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import getFormatPrice from "@/utils/helpers/getFormatPrice";
import helpersExcel from "@/utils/helpers/importExportExcel";
import enums from "@/utils/enums/index.js";

export default {
  name: "VerDetalleProcesoActualizacion",
  props: {
    procesoActualizacionId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    searchPorPeriodo: null,
    searchIcon: enums.icons.SEARCH,
    formEditTitle: "Detalle del proceso de actualización",
    conceptosPorPeriodoHeaders: [
      {
        text: "Agente de cuenta",
        value: "agectaNom",
        sortable: true,
      },
      {
        text: "N° de grupo familiar",
        value: "benGrId",
        sortable: false,
      },
      {
        text: "Concepto",
        sortable: false,
        value: "conceptoNom",
      },
      {
        text: "Importe",
        sortable: false,
        align: "end",
        value: "importe",
      },
      {
        text: "Período desde",
        sortable: false,
        value: "periDesde",
      },
      {
        text: "Período hasta",
        sortable: false,
        value: "periHasta",
      },
    ],
    itemsPorPeriodo: [],
  }),
  created() {
    this.loadDetalleProcesoActualizacion();
  },
  methods: {
    ...mapActions({
      verDetalleProcesoActualizacionXPeriodo:
        "devengamientos/verDetalleProcesoActualizacionXPeriodo",
    }),
    async loadDetalleProcesoActualizacion() {
      const response = await this.verDetalleProcesoActualizacionXPeriodo(
        this.procesoActualizacionId
      );
      this.itemsPorPeriodo = response;
    },
    closeModal() {
      this.$emit("toggleModalSeeProcesoActualizacion");
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
    exportExcel() {
      let result = [];
      this.itemsPorPeriodo.forEach((x) =>
        result.push({
          ["Código del agente"]: x.agectaId,
          ["Agente de cuenta"]: x.agectaNom,
          ["Período desde"]: x.periDesde,
          ["Período hasta"]: x.periHasta,
          ["Concepto"]: x.conceptoNom,
          ["Importe"]: this.addDecimals(x.importe),
          ["N° de grupo familiar"]: x.benGrId,
        })
      );
      let formato = {
        filas: result,
        hoja: "Detalle",
      };
      helpersExcel.excelExport(formato, "Detalle proceso actualización");
    },
  },
};
</script>

<style scoped>
#simple-table-container {
  overflow-x: hidden;
  width: 100%;
  overflow-y: scroll;
  max-height: 250px;
  min-height: 50px;
}
.fontsize {
  font-size: 12px;
}
</style>
